<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <ValidationProvider
          name="npi"
          rules="required|numeric|exact:10|available:NPI"
          v-slot="{ errors }"
        >
          <div class="form-floating mb-3">
            <input
              :value="editableItem.npi"
              @input="handleInput"
              required
              type="text"
              v-uppercase
              class="form-control"
              id="npi"
              name="npi"
              placeholder="Votre npi"
            />
            <label for="nom">NPI</label>
            <span
              v-for="(err, index) in errors"
              :key="index"
              class="text-danger"
              v-html="err"
            ></span>
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="nom"
          :mode="custom"
          :rules="`required|npiValidite:NPI,@npi,@nom,@prenom`"
          v-slot="{ errors }"
        >
          <div class="form-floating mb-3">
            <input
              :value="editableItem.nom"
              @input="handleInput"
              type="text"
              v-uppercase
              class="form-control"
              id="nom"
              name="nom"
              placeholder="Votre nom"
            />
            <label for="nom">Nom</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="prenom"
          :rules="`required|npiValidite:NPI,@npi,@nom,@prenom`"
          v-slot="{ errors }"
        >
          <div class="form-floating mb-3">
            <input
              :value="editableItem.prenom"
              @input="handleInput"
              type="text"
              v-uppercase
              class="form-control"
              id="prenom"
              name="prenom"
              placeholder="Votre prénom"
            />
            <label for="prenom">Prénom</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider
          rules="required|numeric|available:PHONE|exact:10"
          v-slot="{ errors }"
        >
          <div class="form-floating mb-3">
            <input
              :value="editableItem.phone"
              @input="handleInput"
              type="text"
              class="form-control"
              id="phone"
              name="phone"
              placeholder="Votre numéro de téléphone"
            />
            <label for="phone">Téléphone</label>
            <div id="dateNais" class="form-text">
              Nous vous prions de mettre les numéros du Bénin enregistrés en votre propre
              nom. Si vous n’en avez pas, veuillez laisser vide et mettre obligatoirement
              le numéro de votre personne contact ci-dessous.
            </div>
            <span
              v-for="(err, index) in errors"
              :key="index"
              class="text-danger"
              v-html="err"
            ></span>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required|numeric|exact:10" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input
              :value="editableItem.contactProche"
              @input="handleInput"
              type="text"
              class="form-control"
              id="contactProche"
              name="contactProche"
              placeholder="Personne à contacter"
            />
            <label for="contactProche">Téléphone personne à contacter</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <select
                  :value="editableItem.sexe"
                  @input="handleInput"
                  class="form-select form-select mb-4"
                  id="sexe"
                  name="sexe"
                  aria-label="Votre sexe"
                >
                  <option value="M">Masculin</option>
                  <option value="F">Féminin</option>
                </select>
                <label for="sexe">Sexe</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required|minAge:18" v-slot="{ errors }">
              <div class="mb-3">
                <label for="dateNais">Date de naissance</label>
                <p-calendar
                  id="dateNais"
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  v-model="editableItem.dateNais"
                  name="dateNais"
                  :manualInput="true"
                  dateFormat="dd/mm/yy"
                ></p-calendar>
                <small id="dateNais" class="form-text"
                  >Pour ceux qui sont nés vers, écrire 31/12/AAAA.</small
                ><br />
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form- mb-3">
              <label for="metierAppris">Métier que vous avez appris</label>
              <metier-select v-model="editableItem.metierAppris" :options="romes">
                <template #label>
                  <label for="metierAppris">Métier que vous avez appris</label>
                </template>
              </metier-select>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.metierApprisDescription"
                @input="handleInput"
                type="text"
                v-uppercase
                class="form-control"
                id="metierApprisDescription"
                name="metierApprisDescription"
                placeholder="Votre métier actuel"
              />
              <label for="metierApprisDescription"
                >Description du métier appris choisi</label
              >
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
          <!-- <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form- mb-3">
              <label for="metierDesire">Métier (s) que vous voulez exercer</label>
              <metier-select v-model="editableItem.metierDesire" :options="romes">
                <template #label> </template>
              </metier-select>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.metierDesireDescription"
                @input="handleInput"
                type="text"
                v-uppercase
                class="form-control"
                id="metierDesireDescription"
                name="metierDesireDescription"
                placeholder="Votre métier actuel"
              />
              <label for="metierDesireDescription"
                >Description du métier à exercer choisi</label
              >
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider> -->

          <adresse v-model="editableItem" />

          <div class="col-sm-12 mb-4 mt-2">
            <h5>
              Avez-vous une adresse email active devant servir de compte de connexion?
            </h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-check form-check-inline">
                <input
                  v-model="editableItem.typeActivation"
                  class="form-check-input"
                  type="radio"
                  id="typeActivation1"
                  name="typeActivation"
                  :value="'NORMAL'"
                />
                <label class="form-check-label" for="typeActivation1">Oui</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  v-model="editableItem.typeActivation"
                  class="form-check-input"
                  type="radio"
                  id="typeActivation2"
                  name="typeActivation"
                  :value="'CAS_SPECIAL'"
                />
                <label class="form-check-label" for="typeActivation2">Non</label>
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <template v-if="editableItem.typeActivation == 'NORMAL'">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.email"
                  @input="handleInput"
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                  placeholder="Votre email"
                />
                <label for="email">Adresse Email</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
            <div class="row">
              <div class="col-sm-6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      :value="editableItem.password"
                      @input="handleInput"
                      type="password"
                      class="form-control"
                      :class="{
                        'is-valid':
                          editableItem.password != undefined &&
                          editableItem.password == editableItem.password1,
                      }"
                      id="password"
                      name="password"
                      placeholder="Password"
                    />
                    <label for="password">Tapez votre mot de passe de connexion</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      :value="editableItem.password1"
                      :class="{
                        'is-valid':
                          editableItem.password != undefined &&
                          editableItem.password == editableItem.password1,
                      }"
                      @input="handleInput"
                      type="password"
                      class="form-control"
                      id="password1"
                      name="password1"
                      placeholder="Password"
                    />
                    <label for="password1">Confirmer le mot de passe</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </template>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Adresse from "./Adresse.vue";
import MetierSelect from "../../../common/MetierSelect.vue";

export default {
  components: { Adresse, MetierSelect },
  props: ["value"],
  data() {
    return {
      editableItem: this.value,
      dateNais: this.value.dateNais,
    };
  },
  created() {
    this.fetchRomes();
  },
  computed: {
    ...mapGetters({
      romes: "rome/romes",
    }),
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear() - 18);
      return today;
    },
  },
  watch: {
    dateNais(val) {
      this.editableItem.dateNais = this.$dayjs(val).format("DD-MM-YYYY");
      this.$emit("input", this.editableItem);
    },
  },
  methods: {
    ...mapActions({
      fetchRomes: "rome/fetchRomes",
    }),
    custom(context) {
      // if (context.value === "yes") {
      //   return {
      //     on: ["input"],
      //   };
      // }

      return { on: ["blur"] };
    },
    asyncFind(query) {
      this.isLoading = true;
      console.log(query);
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
  },
};
</script>

<style scoped></style>
